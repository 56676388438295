<template>

    <div class="index-module">
        <div class="index-banner flex">
            <div class="window-width">
                <h2>行业翘楚，企业必选，为基层劳动者提供保障</h2>
                <div class="flex-space-between" style="width:1030px;margin:auto;padding-top:86px;">
                    <div>
                        <div class="index-banner-number"><count-up :endVal="31" :duration="2.5"/></div>
                        <div class="index-banner-text">覆盖省份</div>
                    </div>
                    <div>
                        <div class="index-banner-number"><count-up :endVal="325" :duration="2.5"/></div>
                        <div class="index-banner-text">覆盖城市</div>
                    </div>
                    <div>
                        <div class="index-banner-number"><count-up :endVal="50000" :duration="2.5" :options="{separator:''}"/>+</div>
                        <div class="index-banner-text">投保企业</div>
                    </div>
                    <div>
                        <div class="index-banner-number"><count-up :endVal="500000" :options="{separator:''}" :duration="2.5"/>+</div>
                        <div class="index-banner-text">保障人员</div>
                    </div>
                </div>
            </div>
        </div>
        <!--  面包屑  -->
        <div class="index-crumbs">
            <div class="window-width flex-space-between">
                <div class="flex" v-for="(item,index) in crumbsData" :key="index">
                    <img :src="item.imgUrl" alt="" height='38' />
                    <div class="index-crumbs-content">
                        <h3>{{item.title}}</h3>
                        <p>{{item.text}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!--  产品推荐  -->
        <div class="index-recommend">
            <h3 class="index-title">产品推荐</h3>
            <div class="index-subheading">精选热门保险，万千用户的信心之选</div>
            <div class="index-aspiration-content index-recommend-tabs flex">
                <div class="index-tabs-list" :class="{'index-tabs-hover':index == tabsHover}" v-for="(item,index) in recommendData" :key="index" @click="tabsLIst(index)">
                    <img :src="item.icon" alt="" />
                    <h3>{{item.name}}</h3>
                </div>
            </div>
            <div>
                <div v-for="(item,index) in recommendData" :key="index">
                    <div class="flex-space-between index-aspiration-content" style="flex-wrap:wrap" v-if="item.products && item.products.length>0 && index == tabsHover ">
                        <template v-for="(product,productIndex) in item.products" :key="productIndex">
                            <div class="index-recommend-item" v-if="productIndex <= 3" @click="productDetail(item.host,product.productId,product.sourceProductId)">
                                <img :src="product.images[0].url" class="ajax-img" alt="" width='100%' height="100%" />
                                <!--  背景  -->
                                <div class="index-recommend-bg"></div>
                                <div class="index-recommend-introduce">
                                    <h3>{{product.pubNameText}}</h3>
                                    <div class="index-recommend-text" v-html="product.specials"></div>    
                                    <div class="index-recommend-price">
                                        <span>¥</span>
                                        <strong>{{product.pubPrice}}</strong>
                                        <span>起</span>
                                    </div>
                                </div>    
                            </div>
                        </template>        
                    </div>   
                   <div class="more-product" @click="productDetail(item.host)" v-if="item.products && item.products.length>0 && index == tabsHover ">
                        更多保险产品
                   </div>
                </div>
            </div>
        </div>    
        <!--  提供保障  -->
        <div class="index-guarantee">
            <h3 class="index-title">为基层劳动者提供保障</h3>
            <div class="index-subheading">全方位一站式保险解决方案，满足各行业多样化保险产品需求</div>
            <div class="index-aspiration-content flex-space-between">
                <div class="index-guarantee-item" v-for="(item,index) in GuaranteeData" :key="index" @click="windBlank(item.link)">
                    <img class="household-product-img" :src="item.imgUrl" alt="" width='100%' />
                    <div class="index-guarantee-text">
                        <h2>{{item.title}}</h2>
                        <p>{{item.text}}</p>
                        <div class="index-guarantee-detail">查看详情</div>
                    </div>    
                </div>
            </div>
        </div>  
        <!--  专业平台  -->
        <div class="index-major">
            <div class="index-major-content u-p-top-100">
                <h3 class="index-title">可靠、高效、放心的专业平台</h3>
                <div class="index-aspiration-content u-p-top-60">
                    <div class="flex-space-between" style="flex-wrap: wrap">
                        <div class="index-major-item" v-for="(item,index) in MajorData" :key="index">
                            <div class="flex-align-center">
                                <div class="index-major-img">
                                    <img class="index-major-bg" :src="item.imgBg" alt="" width='44' />
                                    <img class="index-major-pic" :src="item.imgUrl" alt="" width='44' />
                                </div>
                                <div class="index-major-introduce">{{item.title}}</div>
                            </div>
                            <p>{{item.text}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--  用户心声  -->
        <div class="index-aspiration u-p-top-100">
            <h3 class="index-title">用户心声</h3>
            <div class="index-subheading">以“为基层劳动者提供保障”为核心使命，让劳动者前行的每一步都充满自信与安定</div>
            <div class="index-aspiration-content user-voice">
                <swiper
                    :modules="[Autoplay,Pagination]"
                    :slidesPerView="1"
                    :space-between="16"
                    :autoplay="{delay: 2500,disableOnInteraction: false }"
                    :pagination="{ clickable: true }"
                    :loop="true">
                    <swiper-slide v-for="item in AspirationImg" :key="item.id">
                        <div class="flex-space-between">
                            <div class="index-aspiration-item" v-for="(sunItem,index) in item.data" :key="index">
                                <img class="index-aspiration-img" src="@/static/images/aspiration.png" alt="" width='50' />
                                <div class="index-aspiration-introduce">
                                    <p>{{sunItem.text}}</p>
                                    <div class="flex-align-center u-p-top-32">
                                        <img :src="sunItem.imgUrl" alt="" width='48' />
                                        <div class="index-aspiration-people">
                                            <h4>{{sunItem.name}}</h4>
                                            <div>{{sunItem.work}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div> 
        <!-- 更多合作客户 -->
        <div class="partner">
            <div class="partner-left-segmentation"></div>
            <h3>更多合作客户</h3>
            <div class="partner-right-segmentation"></div>
        </div>
        <div class="index-swiper">
            <swiper
                ref="swiperInstance"
                @resize="onSwiperResize"
                :modules="[Autoplay]"
                :slidesPerView="slidesPerView"
                :space-between="16"
                :loop="true"
                :autoplay="{delay: 2500,disableOnInteraction: false }">
                <swiper-slide v-for="item in PartnerImg" :key="item.id">
                    <img class="partner-img" :src="item.imgUrl" alt="" width="245" height="117" />
                </swiper-slide>
            </swiper>
        </div>
    </div>
    
</template>
<!-- eslint-disable-next-line vue/multi-word-component-names -->
<script setup>
    import { onMounted, onUnmounted, ref } from 'vue';
    import CountUp from 'vue-countup-v3';
    import { PartnerImg,AspirationImg,MajorData,GuaranteeData,crumbsData } from './data.jsx';
    import { Swiper, SwiperSlide} from 'swiper/vue';
    import { Autoplay,Pagination } from 'swiper/modules';
    import axios from 'axios';
    import 'swiper/css/pagination';
    import 'swiper/css';
    const tabsHover = ref(0);
    const tabsLIst = (index) => {
        tabsHover.value = index;
    }
    // 计算属性，根据屏幕宽度动态设置 slidesPerView
    const computedSlidesPerView = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth > 2000) {
            return 8; // 大屏显示更多幻灯片
        }else if (windowWidth > 1500) {
            return 7; // 大屏显示更多幻灯片
        } else if (windowWidth > 1380) {
            return 5; // 中屏显示适中数量的幻灯片
        } else if (windowWidth > 1200) {
            return 5; // 中屏显示适中数量的幻灯片
        }else if (windowWidth > 768) {
            return 3; // 中屏显示适中数量的幻灯片
        } else {
            return 2; // 小屏只显示一个幻灯片
        }
    };
    const swiperInstance = ref(null);
    const slidesPerView = ref(computedSlidesPerView());
    const onSwiperResize = () => {
        slidesPerView.value = computedSlidesPerView();
        // 更新 Swiper 配置
        //swiperInstance.value.update();
        // 可选：重新滚动到适当位置
       // swiperInstance.value.slideTo(swiperInstance.value.activeIndex, 0, false);
    };
    const windBlank = (url) => {
        window.open(url, '_blank')
    }
    onMounted(() => {
        window.addEventListener('resize', onSwiperResize);
    });

    onUnmounted(() => {
       
        window.removeEventListener('resize', onSwiperResize);
    });
    // 更多产品详情跳转
    const productDetail = (hostUrl,productId,sourceProductId) => {
        const originUrl = new URL(hostUrl).origin;
        let targetUrl = '';
        if (productId) {
            targetUrl = `${originUrl}/employer/static/detail.html?p_id=${productId}&used_id=${sourceProductId}`;
        } else {
            targetUrl = `${originUrl}/employer/static/index.html`;
        }
        window.open(targetUrl, '_blank')
    }
    const recommendData = ref([]);
    const pullClaimOk = () => {
        let hostUrl = window.location.origin+'/employer/v2/index/offical';
        axios.get(hostUrl).then(response => {
            let res = response.data;
            if(res.code == '0' && res.data){
                recommendData.value = res.data?.tabs || []
            }
        }).catch(error => {
            console.error(error.config);
        });
    }
    pullClaimOk()
</script>

<style lang="less">
    @import "@/static/less/index.less";
</style>
