<template>
    <div class="information-container">
        <div class="infor-main">
			<div class="disclosure-crumbs">
				<a @click="routerBtn('/')">首页</a> / <a @click="routerBtn('/disclose')">信息披露</a> / 合作保险公司信息披露
			</div>
			<div class="disclosure-table">
				<h2>合作保险公司信息披露</h2>
				<table>
					<tr>
						<th>合作保险公司名称</th>
						<th>官网</th>
						<th>客服电话</th>
						<th>合作开始时间</th>
						<th>合作结束时间</th>
					</tr>
					<tr v-for="(item,index) in cooperators" :key="index">
						<td>{{item.name}}</td>
						<td><a :href="item.link" target="_blank">{{item.linkText}}</a></td>
						<td>{{item.phone}}</td>
						<td>{{item.startTime}}</td>
						<td>{{item.endTime}}</td>
					</tr>
					</table>
				</div>
			</div>

    </div>
</template>

<script setup>
    import { useRouter } from 'vue-router';
	import { cooperators } from '@/views/data.jsx';
	const router = useRouter();
	const routerBtn = (url) => {
        window.scrollTo(0, 0);
        router.push(url);
    }
</script>

<style lang="less" scoped>
    .information-container{
        padding-top:78px;
        padding-bottom:100px;
    }
    .infor-main{
        width:1200px;
        margin:auto;
    }
    .disclosure-crumbs{
        text-align: left;
        line-height: 60px;
        color: #999999;
        font-size: 16px;
    }
    .disclosure-crumbs a{
        font-size: 16px;
        color: #999999;
    }
    .disclosure-crumbs a:hover{
        color:#0068B7;
    }
    .disclosure-table{
	padding:30px;
	background: #fff;
}
.disclosure-table h2{
	color: #333333;
	font-weight: bold;
	text-align: center;
	font-size: 24px;
	padding-bottom:30px;
}
.disclosure-table table{
	border:1px solid #EEEEEE;
	width:100%;
	border-left:none;
    text-align: left;
}
.disclosure-table table th{
	background: #0068B7;
	line-height: 50px;
	font-size:16px;
	color: #FFFFFF;
	text-align: left;
	padding-left:20px;
	border-left:1px solid #EEEEEE;
}
.disclosure-table table td{
	border-left:1px solid #EEEEEE;
	padding:18px 20px;
	color: #333333;
	font-size:16px;
	border-top:1px solid #EEEEEE;
}
.disclosure-table table td a{
	color: #1890FF;
	font-size:16px;
}
</style> 