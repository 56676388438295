import axios from 'axios'
import { message, Modal, notification } from 'ant-design-vue'

// 统一配置
const httpRequest = axios.create({
  baseURL: 'https://mbtest.meibaokeji.com.cn/',
  responseType: 'json',
  validateStatus(status) {
    // 200 外的状态码都认定为失败
    return status === 200
  }
})

// 拦截请求
httpRequest.interceptors.request.use((config) => {

  // 有 token就带上
  
  config.headers.Device = 'crm-pc'
  return config
}, (error) => {
  return Promise.reject(error)
})


let ignoreCode = ["012028", "012029"]

// 拦截响应
httpRequest.interceptors.response.use((res) => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 0
  // 获取错误信息
  const msg = res.data.msg
  if (code !== "200" && code !== 0) {
    notification.error({
      message: msg,
      duration: 4
    })
  }
  return res
}, (error) => {
  if (error.response) {
    const errorMessage = error.response.data === null ? '系统内部异常，请联系网站管理员' : (error.response.data.message || error.response.data.msg)
    switch (error.response.status) {
      case 404:
        notification.error({
          message: '接口提示',
          description: '很抱歉，资源未找到',
          duration: 4
        })
        break
      case 403:
        Modal.confirm({
          title: '未授权',
          content: '很抱歉，您暂无权限访问该资源，请联系管理员授权',
          cancelText: '我有这权限',
          okText: '我知道了',
          mask: false,
          onCancel: () => {
            // 刷新用户权限
            return new Promise((resolve, reject) => {
              request.get('user/permission').then(r => {
               
                console.log(r,resolve, reject)
                location.reload()
              })
            })
          }
        })
        break
      case 401:
       
        location.reload()
        break
      default:
        if (ignoreCode.includes(error.response.data.code)) {
          return error.response
        }else {
          notification.error({
            message: errorMessage,
            duration: 4
          })
        }
        break
    }
  }
  return Promise.reject(error)
})

const request = {
  postJson(url, params) {
    return httpRequest.post(url, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  post(url, params) {
    return httpRequest.post(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  put(url, params) {
    return httpRequest.put(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  putJson(url, params) {
    return httpRequest.put(url, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  get(url, params) {
    return httpRequest.get(url, { params })
  },
  delete(url, params) {
    return httpRequest.delete(url, { params })
  },
  export(url, params = {}) {
    message.loading('导出数据中')
    return httpRequest.post(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      responseType: 'blob'
    }).then((r) => {
      const content = r.data
      const blob = new Blob([content])
      const fileName = `${new Date().getTime()}_导出结果.xlsx`
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      } else {
        navigator.msSaveBlob(blob, fileName)
      }
    }).catch((r) => {
      console.error(r)
      message.error('导出失败')
    })
  },
  download(url, params, filename) {
    message.loading('文件传输中')
    return httpRequest.post(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      responseType: 'blob'
    }).then((r) => {
      const content = r.data
      const blob = new Blob([content])
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = filename
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      } else {
        navigator.msSaveBlob(blob, filename)
      }
    }).catch((r) => {
      console.error(r)
      message.error('下载失败')
    })
  },
  downloadJson(url, params, filename) {
    message.loading('文件传输中')
    return httpRequest.post(url, params, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    }).then((r) => {
      const content = r.data
      const blob = new Blob([content])
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = filename
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      } else {
        navigator.msSaveBlob(blob, filename)
      }
    }).catch((r) => {
      console.error(r)
      message.error('下载失败')
    })
  },
  upload(url, params = {}) {
    const formParams = new FormData()
    Object.keys(params).forEach(key => {
      formParams.append(key, params[key])
    })
    console.log(params);
    console.log(formParams.get('file'));
    return httpRequest.post(url, formParams, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  downloadGet(url, params = {}) {
    const formParams = new FormData()
    Object.keys(params).forEach(key => {
      formParams.append(key, params[key])
    })
    return httpRequest.get(url, formParams, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  formDataRequest(url, method = "post", params = {}) {
    const formParams = new FormData()
    Object.keys(params).forEach(key => {
      formParams.append(key, params[key])
    })
    return httpRequest[method](url, formParams, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  batchFilesUpload(url, params = {}) {
    return httpRequest.post(url, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

export default request
