import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import ClaimSettlement from '../views/ClaimSettlement.vue';
import AboutUs from '../views/AboutUs.vue';
import Disclose from '../views/InforDisclose.vue';
import CdisclosureDetail from '../views/CdisclosureDetail.vue';
import AgentProducts from '../views/AgentProducts.vue';
import ListDetail from '../views/ListDetail.vue';
const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/claim',
    name: 'ClaimSettlement',
    component: ClaimSettlement
  },
  {
    path: '/about',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/disclose',
    name: 'Disclose',
    component: Disclose
  },
  {
    path: '/cdisclosure',
    name: 'CdisclosureDetail',
    component: CdisclosureDetail
  },
  {
    path: '/agent',
    name: 'AgentProducts',
    component: AgentProducts
  },
  {
    path: '/listdetail',
    name: 'ListDetail',
    component: ListDetail
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),//createWebHashHistory(), // 使用 Hash 模式
  routes
});

export default router;