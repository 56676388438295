<template>
    <a-space direction="vertical" :style="{ width: '100%' }">
        <a-layout>
            <a-layout-header class="fixed-header">
                <ComHeader :roll="rollValue" :urlPath="path" :key="path" @change="changePath"></ComHeader>
            </a-layout-header>
            <a-layout-content>
                <router-view />
            </a-layout-content>
            <a-layout-footer class="font-name" :style="footerStyle">
                <ComFooter></ComFooter>
            </a-layout-footer>
        </a-layout>
   </a-space> 
</template>

<script setup>
    import ComHeader from '@/components/common/ComHeader.vue'
    import ComFooter from '@/components/common/ComFooter.vue'
    import {ref,watch,onMounted, onUnmounted } from 'vue';
    import {useRoute } from 'vue-router';
    const footerStyle = { padding:0,background:'#1F2B37', };
    const rollValue = ref(false);
    const route = useRoute();
    const path = ref(window.location.pathname);
    const handleScroll = () => {
        if(window.scrollY > 100){
            rollValue.value = true;
        }else{
            rollValue.value = false;
        }
    };
    const changePath = (url) => {
        path.value = url;
    }
    watch(() => route.path, (newVal, oldVal) => {
            path.value = newVal
            console.log('路由变化了：', oldVal, '=>', newVal);
            
        },
        { immediate: true } // 设置 immediate 为 true，以便在组件挂载时立即执行一次回调
        );
    onMounted(() => {
        window.addEventListener('scroll', handleScroll);
    });
    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
    });
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
       
    }
    .fixed-header{
        
        position: fixed;
        background: none !important;
        width: 100%;
        height: 78px !important;
        top: 0;
        left: 0;
        padding:0 !important;
        z-index:9;
    }
    .html-width{
        max-width: 1200px;
        margin:auto;
    }
    ::-webkit-scrollbar {
        width: 6px
    }

    ::-webkit-scrollbar:horizontal {
        height: 6px
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px
    }

    ::-webkit-scrollbar-thumb {
        background-color: #0003;
        border-radius: 10px;
        transition: all .2s ease-in-out
    }

    ::-webkit-scrollbar-thumb:hover {
        cursor: pointer;
        background-color: #0000004d
    }

</style>
