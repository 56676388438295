<template>
    <div class="header-bg"><!-- :class="{'scroll-bg':headerShow && !roll}"  -->
      <img :src="logoHover" class="header-logo" width="173" height="45" @click="routerBtn('/')"/>
      <div class="header-nav window-width">
            <ul class="flex">
                <li :class="{'hover':urlPath == '/'}">
                    <a @click="routerBtn('/')">首页</a>
                    <div class="nav-line"></div>
                </li>
                <li class="flex-align-center" @mouseover="ShowInsuranceMall" @mouseleave="HideInsuranceMall">
                    <img src="@/static/images/hot.png" alt="" style="width:17px;height:17px;">
                    <a style=" font-style: italic;padding-left:5px;font-weight: bold;">保险商城</a>
                    <div class="nav-line"></div>
                    <!-- 二级导航  v-if="navTwoShow"-->
                    <div class="header-nav-two" :class="{'header-nav-hover':navTwoShow}">
                        <div style="display: flex;justify-items: center;">
                            <div class="header-nav-item" :class="{'header-nav-item-hover':navTwoHover === index}" @click="clickOverNav(item.link)" v-for="(item,index) in navTwoData" :key="index" @mouseover="mouseOverNav(index)" @mouseleave="mouseLeaveNav(index)">
                                <img :src="item.imgUrl" alt="" style="height:88px;">
                                
                                <div class="flex-align-center u-p-bottom-8">
                                    <h2>{{item.title}}</h2>
                                    <img  class="navmore-hover" v-if="navTwoHover === index" src="@/static/images/more-hover.png" alt="" width="7">
                                    <img v-else src="@/static/images/more.png" alt="" width="7">
                                </div>
                                <p v-for="(itemText,indexText) in item.text" :key="indexText">{{itemText}}</p>
                            </div>
                        </div>    
                    </div> 
                </li>
                <li :class="{'hover':urlPath == '/claim' || urlPath=='/listdetail'}"><a @click="routerBtn('/claim')">理赔服务</a><div class="nav-line"></div></li>
                <li :class="{'hover':urlPath == '/disclose' || urlPath == '/cdisclosure' || urlPath == '/agent'}"><a @click="routerBtn('/disclose')">信息披露</a><div class="nav-line"></div></li>
                <li :class="{'hover':urlPath == '/about'}"><a @click="routerBtn('/about')">关于我们</a><div class="nav-line"></div></li>
                <li @mouseover="ShowWabMall" @mouseleave="HideWebMall">
                    <a>移动端</a>
                    <div class="nav-line"></div>
                    <img v-if="webShow" class="web-img" :class="{'web-imghover':webShow}" width="539" src="@/static/images/web.png"/>
                </li>
                <li class="header-phone flex-align-center">
                    <img :src="phoneIconHover" alt="" style="width:16px;height:16px;"/>
                    <span style="padding-left:8px;">400-180-1616</span>
                </li>
            </ul>
      </div>
    </div>
    <!--  在线咨询  -->
    <div class="header-oscillation">
        <div style="position: relative;">
            <div @mouseover="mouseOver(1)" @mouseleave="mouseLeave(1)" style="position:relative">
                <img :src="onlineShow" width="56" class="oscillation-img"  alt=""/>
                <div style="position: absolute;right:54px;top:0;padding-right:10px;width:165px;height:241px" v-if="onlineNumber == 1">
                    <div class="header-oscillation-hover">
                        <h2>电话咨询</h2>
                        <div class="header-oscillation-phone">400-180-1616</div>
                        <h2 style="padding-bottom:10px;">微信扫码咨询</h2>
                        <img src="@/static/images/tuankf.png" alt="" style="width:108px;height:108px;"/>
                        <div class="triangle-right"></div>
                    </div>
                </div>    
            </div>
            <div @mouseover="mouseOver(2)" @mouseleave="mouseLeave(2)">
                <img :src="officialShow" width="56" class="oscillation-img" alt=""/>
                <div style="position: absolute;right:54px;top:0;padding-right:10px;width:165px;height:241px" v-if="onlineNumber == 2">
                    <div class="header-oscillation-hover header-oscillation-top" style="width:270px;">
                        <h2>微信扫码关注</h2>
                        <div class="header-oscillation-text" style="padding-bottom:10px;">投保/理赔/查保单/更多保险知识</div>
                        <div style="display: flex;justify-content: space-between;">
                            <div>
                                <img src="@/static/images/jd-official.png" alt="" style="width:108px;height:108px;"/>
                                <div style="font-size: 14px;color:#999;text-align: center;line-height: normal;">美保家电师傅</div>
                            </div>
                            <div>
                                <img src="@/static/images/gk-official.png" alt="" style="width:108px;height:108px;"/>
                                <div style="font-size: 14px;color:#999;text-align: center;line-height: normal;">美保高空保障</div>
                            </div>
                        </div>
                        <div class="triangle-right"></div>
                    </div>
                </div>    
            </div>
            <div>
                <img :class="{'oscillation-showimg':roll}" style="visibility:hidden" :src="upShow" width="56" class="oscillation-img" @click="scrollTop" alt="" @mouseover="mouseOver(3)" @mouseleave="mouseLeave(3)"/>
            </div>
        </div>
    </div>
    <!--  底部咨询consultingShow  -->
    <div class="consulting-service" v-if="consultingShow && roll">
        <div class="window-width consulting-service-hover" style="position: relative;">
            <div style="position:relative;left:390px;bottom:-75px;">
                <a-form layout="inline" :model="formState">
                    <a-form-item>
                        <a-input style="width:160px;" v-model:value="formState.mobile" size="large" maxlength="11" type="number" placeholder="请输入手机号"/>
                    </a-form-item>
                    <a-form-item>
                        <a-input style="width:160px;" v-model:value="formState.job" size="large"  placeholder="请输入您的行业"/>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" size="large" @click="submit">
                          <img src="@/static/images/submit.png" width="24" style="padding-right:8px;"/>  立即预约保险顾问
                        </a-button>
                    </a-form-item>
                </a-form>
            </div>
            <div class="service-close" @click="serviceClose"></div> 
        </div>
    </div>    
</template>

<script setup>
    import { ref, defineProps, onMounted,defineEmits,reactive } from 'vue';
    import { useRouter } from 'vue-router';
    import { navTwoData } from '@/views/data.jsx';
    import logoHover from '@/static/images/nav-logohover.png?v=20250301';
    import phoneIconHover from '@/static/images/phone-icon-hover.png';
    import { message } from 'ant-design-vue';
    import onlineImg from '@/static/images/online.png';
    import onlineImgHover from '@/static/images/online-hover.png';
    import axios from 'axios';
    import officialImg from '@/static/images/official.png';
    import officialImgHover from '@/static/images/official-hover.png';
    import upImg from '@/static/images/up.png';
    import upImgHover from '@/static/images/up-hover.png';
    const router = useRouter();
    const props = defineProps({
        roll: { type: Boolean, default: false },
        urlPath: { type: String, default: '/' }
    });
    const emit = defineEmits(['change'])
    const headerShow = ref(false);

    const onlineShow = ref(onlineImg)
    const officialShow = ref(officialImg)
    const upShow = ref(upImg)
    const onlineNumber = ref(0);
    const consultingShow = ref(true); 
    const webShow = ref(false);
    const formState = reactive({
        name : '',
		job : '',
		mobile :'',
		jobTitle :'1',
		source : 23
    }); 
    // 导航显示逻辑
    const updateLogo = () => {
        if (props.urlPath === '/') {
            if(props.roll){
                return
            }
            
            headerShow.value = true;
        } else {
            
            headerShow.value = false;
        }
    };
    //  子导航hover效果
    const navTwoHover = ref('');
    const navTwoShow = ref(false);
    const mouseOverNav = (index) => {
        navTwoHover.value = index;
    }
    const mouseLeaveNav = () => {
        navTwoHover.value = ''
    }
    const clickOverNav = (url) => {
        window.open(url, '_blank');
    }
    // 保险商城
    const ShowInsuranceMall = () => {
        navTwoShow.value = true
    }
    const HideInsuranceMall = () => {
        navTwoShow.value = false
    }
    // 在组件挂载时调用 updateLogo
    onMounted(() => {
        updateLogo();
    });
 
    const routerBtn = (url) => {
        emit('change', url);
        window.scrollTo(0, 0);
        router.push(url);
    }
    const mouseOver = (index) => {
        onlineNumber.value = index;
        if(index === 1){
            onlineShow.value = onlineImgHover;
        }else if(index === 2){
            officialShow.value = officialImgHover;
        }else if(index === 3){
            console.log(index)
            upShow.value = upImgHover;
        }
    }
    const mouseLeave = (index) => {
        onlineNumber.value = 0;
       if(index === 1){
            onlineShow.value = onlineImg;
       }else if(index === 2){
            officialShow.value = officialImg;
       }else if(index === 3){
            upShow.value = upImg;
       }
    }
    const ShowWabMall = () => {
        webShow.value = true;
    }
    const HideWebMall = () => {
        webShow.value = false;
    }
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth' // 实现平滑滚动
        });
    }
    const checkPhoneNo = (val) =>{
			var reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
			return reg.test(val);
		}
    const submit = () => {
        console.log(formState)
        if(!formState.mobile){
            message.error('请输入手机号');
            return 
        }else if(!checkPhoneNo(formState.mobile)){
			message.error('请输入正确的手机号');
            return 
        }
        if(!formState.job){
            message.error('请输入您的行业');
            return 
        }
        let hostUrl = window.location.origin+'/employer/survey/add';
        axios.post(hostUrl,formState).then(res => {
            console.log(res)
            serviceClose()
        }).catch(error => {
            console.error(error.config);
        });
    }
    const serviceClose = () => {
        formState.mobile = '';
        formState.job = '';
        consultingShow.value = false;
    }
</script>
<style lang="less" scoped>
    .web-img{
        position: absolute;
        top:78px;
        left:50%;
        margin-left:-270px
    }
    .consulting-service{
        height:106px;
        width:100%;
        background: url("../../static/images/consulting-service.png") no-repeat center center;
        background-size:cover !important;
        position: fixed;
        left:0;
        bottom:0;
    }
    .consulting-service-hover{
        background: url("../../static/images/consulting-servicehover.png") no-repeat center center;
        height:146px;
        background-size:100% 100%;
        position:relative;
        top:-37px;
        z-index:5
    }
    .header-nav-item{
        text-align: left;
        width:350px;
        padding:50px 12px 100px 12px;
        cursor: pointer;
        position: relative;
        .header-nav-tabs{
            width: 85px;
            position: absolute;
            left: 50px;
            top: 85px;
        }
        h2{
            font-size: 20px;
            font-weight: bold;
            line-height: 30px;
            color: #333333;
            padding-right:7px; 
        }
        p{
            font-size: 16px;
            font-weight: normal;
            line-height: 24px;
            color: #666666;
            padding-top:4px;
        }
    }
    .navmore-hover{
        position: relative;
        left: 0;
        opacity: 1;
    }
    .header-nav-item-hover{
        background:#F3F6F9;
        h2{
            color:#2072F5
        }
        .navmore-hover{
            -webkit-animation: fadeInRight .15s linear forwards;
             animation: fadeInRight .15s linear forwards;
        }
    }
    @-webkit-keyframes fadeInRight {
        0% {
            opacity: 1;
            left:0
        }
        100% {
            opacity: 1;
            left:8px;
        }
    }

    @keyframes fadeInRight {
        0% {
            opacity: 1;
            left:0
        }
        100% {
            opacity: 1;
            left:8px;
        }
    }
    .header-nav-two{
        width:100%;
        background: #fff;
        display: flex;
        justify-content: center;
        position: fixed;
        left:0;
        top:78px;
        box-shadow:0px 0.06rem 0.12rem 0px rgba(0,0,0,0.05);
        -webkit-transition: all .5s ease;
        -o-transition: all .5s ease;
        -moz-transition: all .5s ease;
        transition: all .5s ease;
        height:0;
        overflow:hidden;
    }
    .header-nav-hover{
        height:332px;
    }
    .web-img{
        
        opacity: 0;
        transition: box-shadow 0.5s ease;
    }
    .web-imghover{
        opacity: 1;
        box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.12);
    }
    .triangle-right{
        width: 0;
        height: 0;
        border-top: 10px solid transparent; /* 上边框透明 */
        border-bottom: 10px solid transparent; /* 下边框透明 */
        border-left: 10px solid #fff; 
        position: absolute;
        top: 20px;
        right: -10px;
        z-index: 12;
    }
    .header-oscillation-text{
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        padding:5px 0 10px 0;
        line-height: normal;
    }
    .header-oscillation-phone{
        line-height: normal;
        font-size: 16px;
        font-weight: normal;
        color: #2072F5;
        padding-top:4px;
        padding-bottom:16px;
        line-height: normal;
    }
    .header-oscillation-hover{
        background:#fff;
        padding:20px;
        width:150px;
        position: absolute;
        top:0;
        right:15px;
        border-radius: 10px;
        box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.05);
        text-align: left;
    }
    .header-oscillation-top{
        top:155px
    }
    .header-oscillation-hover h2{
        font-size: 16px;
        color: #000000;
        font-weight: bold;
        line-height:normal;
    }
    .header-oscillation{
        position: fixed;
        right: 32px;
        top:50%;
        transform: translateY(-50%);
    }
    .oscillation-img{
        display: block;
        margin-bottom:16px;
        cursor: pointer;
        border-radius:56px;
        box-shadow: 0px 2px 30px 0px rgba(0, 49, 102, 0.12);
    }
    .oscillation-img:hover{
        border-radius:56px;
        box-shadow: 0px 2px 30px 0px rgba(0, 49, 102, 0.12);
    }
    .oscillation-showimg{
        visibility: visible !important;
    }
   .header-bg{
        background: #FFFFFF;
        backdrop-filter: blur(10.88px);
        height: 78px !important;
        padding:0 4.16%;
        position: relative;
        box-shadow:0px 0.06rem 0.12rem 0px rgba(0,0,0,0.05); 
   }
   .header-logo{
        position: absolute;
        left:4.16%;
        top:16.5px;
   }
   .header-nav{
        margin:auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 78px;
   }
   .header-nav ul li{
        margin:0 28px;
        position: relative;
        height: 78px;
   }
   
   .nav-line{
        width:26px;
        height: 3px;
        background: #FFFFFF;
        position:absolute;
        bottom:15px;
        left:50%;
        transform: translateX(-50%);
        -webkit-animation: fadeIn 1s linear forwards;
        animation: fadeIn 1s linear forwards;
        display:none;
   }
   @-webkit-keyframes fadeIn {
        0% {
            opacity: 0
        }

        to {
            opacity: 1
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0
        }

        to {
            opacity: 1
        }
    }
   .header-nav  ul li.hover .nav-line{
        display: block;
   }
   .header-nav a{
        display: block;
        font-size: 18px;
        color:  rgba(0, 0, 0, 0.85);
        line-height: 78px;
   }
   .header-phone{
        font-size: 18px;
        font-weight: bold;
        color:  rgba(0, 0, 0, 0.85);
   }
   .scroll-bg{
        background: rgba(0, 13, 31, 0.35); 
   }
   .scroll-bg .header-nav a,.scroll-bg .header-phone{
        color:#fff;
   }
   .scroll-bg ul li:hover .nav-line{ display: block;}
   .header-bg .header-nav  ul li:hover a{
        color:#2072F5
   }
   .header-bg .header-nav  ul li:hover .nav-line{
        background: #2072F5;
        display: block;
   }
   .header-bg ul li.hover .nav-line{display: block;}
   .header-bg ul li.hover a{ color:#2072F5}
   .header-bg .header-nav  ul li.hover .nav-line{
        background: #2072F5;
   }
   .scroll-bg ul li.hover a{ color:#fff}
   .scroll-bg .header-nav  ul li.hover .nav-line{
        background: #fff;
   }
   .scroll-bg .header-nav  ul li:hover a{
        color:#fff;
   }
   .scroll-bg .header-nav  ul li:hover .nav-line{
        background: #fff;
   }
   .scroll-bg .header-nav  ul li:hover .nav-line{
        background: #fff;
   }
   .service-close{
        cursor: pointer;
        width:16px;
        height:16px;
        right:0;
        top:55px;
        position: absolute;
   }
</style>
