<template>
    <div class="bottom-nav html-width">
        <a-flex gap="middle" align="start" horizontal>
            <div class="footer-products">
                <h2>保险产品</h2>
                <p v-for="(item,index) in navTwoData" :key="index"><a :href="item.link" target="_blank">{{item.footerTitle}}</a></p>
            </div>
            <div class="footer-products">
                <h2>关于我们</h2>
                <p><a @click="routerBtn('/about')">公司介绍</a></p>
                <p><a @click="routerBtn('/about',2)">联系我们</a></p>
                <p><a @click="routerBtn('/disclose')">信息披露</a></p>
            </div>
             <div class="footer-hotline">
                <h2>全国咨询热线</h2>
                <a-flex style="padding-top:14px;">
                    <img src="@/static/images/phone.png" alt="" style="width:16px;height:16px">
                    <div style="padding-left:11px;position:relative;top:-3px;">
                        <h2>400-180-1616</h2>
                    </div>
                </a-flex>
                <a-flex style="padding-top:20px;" align="center">
                    <img src="@/static/images/email.png" alt="" style="width:16px;height:16px">
                    <div class="bottom-text">
                        xiesq@meibaokeji.com
                    </div>
                </a-flex>
                <a-flex style="padding-top:20px;">
                     <img src="@/static/images/address.png" alt="" style="width:16px;height:16px;margin-top:2px;">
                     <div class="bottom-text">
                        {{address}}
                    </div>
                </a-flex>
            </div>
             <div style="flex:1">
                <h2>扫码关注公众号</h2>
                <a-flex style="padding-top:20px;">
                    <div style="flex:1">
                        <img src="@/static/images/jd-official.png" alt="" style="width:83px;height:83px;">
                        <p class="official-opacity">美保家电师傅</p>
                    </div>
                     <div style="flex:1">
                        <img src="@/static/images/gk-official.png" alt="" style="width:83px;height:83px;">
                        <p class="official-opacity">美保高空保障</p>
                    </div>
                </a-flex>
            </div>
        </a-flex>
    </div>
    <div class="footer-bottom">
        <div style="flex:1">
            
            <img src="@/static/images/logo-name.png" alt="" height="45">
        </div>
        <div class="footer-bottom-text">
            <span>{{copyright}}</span>
            <span><a style="color:#fff" href="https://beian.miit.gov.cn" target="_blank">{{RecordNumber}}</a></span>
            <!--<span>
                <img src="@/static/images/record.png" alt="" style="width:20px;height:20px;">
                京公网安备11010502047390号
            </span>-->
        </div>
    </div>  
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { navTwoData } from '@/views/data.jsx';
    const router = useRouter();
    const address = "广东省佛山市顺德区北滘镇君兰社区诚德路1号美的财富广场4栋27层2706"
    const copyright = '广东美保保险经纪有限公司 版权所有'
    const RecordNumber = ref('')
    const getInsureType = ()=> {
        const host = window.location.host;
        const hostHref= /.*(meibaokeji.cn).*/i;
        const hostHref1= /.*(iebx.cn).*/i;
        const hostHref2= /.*(xinshiminbao.cn).*/i;
        if(hostHref.test(host)){
            return 2;
        }else if(hostHref1.test(host)){
            return 3;
        }else if (hostHref2.test(host)){
            return 4;
        }
        return 1;
    }
    const init = () => {
        const type = getInsureType();
        if(type == 1){
			RecordNumber.value = 'ICP备案号：粤ICP备2025359490号-1'
		}else if(type==2){
			RecordNumber.value = 'ICP备案号：粤ICP备2025359490号-2'
         }else if(type==3){
			RecordNumber.value ='ICP备案号：粤ICP备2025359490号-3'
		}else if(type==4){
			RecordNumber.value ='ICP备案号：粤ICP备2025359490号-4'
		}
    }
    const routerBtn = (url,number) => {
        if(number == 1){
            window.scrollTo(0, 670);
        }else if(number == 2){
            window.scrollTo(0, 1900);
        }else{
            window.scrollTo(0, 0);
        }
        router.push(url);
    }
    onMounted(() => {
        init();
    }); 
</script>
<style>
    .official-opacity{
        opacity: 0.6;
    }
    .bottom-nav{
        background:#1F2B37;
        padding:54px 0 22px 0;
        text-align:left;
    }
    .bottom-nav h2{
        color:#fff;
        font-size:18px;
        padding-bottom:0;
        margin-bottom:0;
        
    }
    .bottom-nav  p{
        padding-top:20px;
        margin-bottom:0;
        color: #FFFFFF;
        font-size: 14px;
    }
    .bottom-nav  p a{
        color: #FFFFFF;
        font-size: 14px;
        opacity: 0.6;
        cursor: pointer;
    }
    .bottom-nav  p a:hover{
        color:#fff;
        opacity:1;
        text-decoration:underline;
    }
    .footer-products{
        width:215px
    }
    .footer-hotline{
        width:390px
    }
    .bottom-text{
        padding-left:10px;
        color: #FFFFFF;
        font-size: 14px;
        opacity: 0.6;
        line-height:20px;
        max-width:300px;
    }
    .footer-bottom{
        padding:30px 0;
        background:#1A212E;
    }
    .footer-bottom-text{
        padding-top:20px;
        color: #FFFFFF;
        font-size: 14px;
        opacity: 0.6;
        line-height: 20px;
    }
    .footer-bottom-text span{padding-right:35px;}
</style>
