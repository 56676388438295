<template>
    <div class="claim-container">
        <!-- Banner区域 -->
        <div class="claim-banner">
            <div class="window-width" style="position: relative;height:521px;display: flex;align-items: center;justify-content: flex-end;">
                <img src="@/static/images/claim-ewm.png?v=20250224" alt="" style="width:13vw;min-width:260px;">
            </div>
        </div> 
        <!--  服务  -->
        <div class="claim-service">
            <div class="window-width">
                <h3>极速理赔，省心服务</h3>
                <div class="claim-service-text">
                    一站式极速理赔服务，省心高效，保障无忧
                </div>
                <div class="claim-service-content">
                    <div class="claim-service-item" v-for="(item,index) in ServiceData" :key="index">
                        <div class="claim-service-icon">
                            <img :src="item.imgUrl" alt="" width="44">
                            <h4>{{item.title}}</h4>
                        </div>
                        <div class="claim-service-text">
                            {{item.text}}
                        </div>    
                    </div>
                </div>
            </div>
        </div>

        <div class="claim-process claim-service">
            <div class="window-width">
                <h3>理赔流程</h3>
                <div class="claim-service-text">
                    理赔全程有协助，速度更快，效率更高，过程更省心
                </div>
                <div class="flex-space-between u-p-top-130" ref="scrollFirst" style="padding-left:57px;">
                    <div class="claim-process-left">
                        <div class="process-baoan">
                            <img src="@/static/images/process-baoan.png?v=20250223" alt="">
                        </div>
                        <h2><span>第一步：</span>在线报案</h2>
                        <div class="claim-process-text">
                            发生事故时可通过<strong>「美保理赔助手小程序」</strong>和<strong>「美保家电师傅公众号」</strong>进行线上报案，报案后理赔专员一对一全程跟进
                        </div>
                        <img src="@/static/images/process-guanzhu.png?v=20250223" alt="">
                    </div>
                    <div style="position: relative;width:637px;height:502px;">
                        <img class="rise-up" src="@/static/images/process-pic1.png?v=20250223" style="position: absolute;right:-33px;top:-33px;z-index:2" alt="">
                    </div>
                    
                </div>

                <div class="flex-space-between u-p-top-130" ref="scrollTwo">
                    <div style="position: relative;width:637px;height:442px;">
                        <img class="rise-up" src="@/static/images/process-pic2.png?v=20250223" style="position:absolute;left:-34px;top:-32px" alt="">
                    </div>
                    
                    <div class="claim-process-left" style="padding-right:81px;">
                        <div class="process-baoan">
                            <img src="@/static/images/process-baoan1.png?v=20250223" alt="">
                        </div>
                        <h2><span>第二步：</span>提交理赔材料</h2>
                        <div class="claim-process-text">
                            案件受理后，专属理赔员全程跟踪，提供详尽且专业理赔指导，并支持在线提交理赔材料，便捷高效，省时省心
                        </div>
                    </div>
                    
                </div>

                <div class="flex-space-between u-p-top-130" style="padding-right:81px;" ref="scrollThree">
                   
                    <div class="claim-process-left" style="padding-left:57px;">
                        <div class="process-baoan">
                            <img src="@/static/images/process-baoan3.png?v=20250223" alt="">
                        </div>
                        <h2><span>第三步：</span>理赔材料审核</h2>
                        <div class="claim-process-text">
                            理赔资料提交齐全后，理赔专员进行在线审核，审核无误后移交保司（大额案件需邮寄材料），并实时反馈理赔进度，保障处理时效
                        </div>
                    </div>
                    <div style="position: relative;width:374px;height:491px;">
                        <img  class="rise-up" src="@/static/images/process-pic3.png?v=20250223" style="position:absolute;left:-34px;top:-32px" alt="">
                    </div>
                </div>

                <div class="flex-space-between u-p-top-130" ref="scrollFour">
                    <div style="position: relative;width:627px;height:442px;">
                       <img  class="rise-up" src="@/static/images/process-pic4.png?v=20250223" style="position:absolute;left:-34px;top:-32px" alt="">
                   </div>
                   <div class="claim-process-left" style="padding-right:81px;">
                       <div class="process-baoan">
                           <img src="@/static/images/process-pic5.png?v=20250223" alt="">
                       </div>
                       <h2><span>第四步：</span>成功获得理赔</h2>
                       <div class="claim-process-text">
                        对属于审核无误的案件，最快当天可结案并支付赔款（到账时间以银行实际到账时间为准）
                       </div>
                   </div>
               </div>
            </div>    
        </div>

        <div class="claim-service">
            <div class="window-width">
                <h3>真实案例</h3>
                <div class="claim-service-text">
                    见证保障力量，让每一份信任都有回应
                </div>
                <div class="claim-case-content flex-space-between">
                    <div class="claim-case-item" v-for="(item,index) in caseData" :key="index" @click="claimCaseDetail(index)">
                        <img :src="item.imgUrl" width="380" alt="" style="border-bottom-left-radius: 36px;border-bottom-right-radius: 36px;">
                        <div class="claim-case-title">
                            <h4>{{item.title}}</h4>
                            <div class="claim-case-text">
                                {{item.text}}
                            </div>
                            <div class="claim-case-detail" @click="claimCaseDetail(index)">
                                查看详情<img src="@/static/images/back-hover.png?v=20250223" alt="" style="top:-1px;position: relative;" width="16">
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
    import {onMounted, onUnmounted,ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { ServiceData,caseData } from './data.jsx';
    const router = useRouter();
    const claimCaseDetail = (index) => {
        let url='/listdetail?id='+index;
        window.scrollTo(0, 0);
        router.push(url);
    }
    const scrollFirst = ref(null);
    const scrollTwo = ref(null);
    const scrollThree = ref(null);
    const scrollFour = ref(null);
    const handleScroll = () => {
        let scrollRects = window.scrollY + window.innerHeight
        console.log(scrollRects)
        if(scrollRects > 3880){
            scrollFour.value.classList.add('active')
            scrollFirst.value.classList.remove('active')
            scrollTwo.value.classList.remove('active')
            scrollThree.value.classList.remove('active')
        }else if(scrollRects > 3225){
            scrollThree.value.classList.add('active')
            scrollFirst.value.classList.remove('active')
            scrollTwo.value.classList.remove('active')
            scrollFour.value.classList.remove('active')
        }else if(scrollRects > 2570){
            scrollTwo.value.classList.add('active')
            scrollFirst.value.classList.remove('active')
            scrollThree.value.classList.remove('active')
            scrollFour.value.classList.remove('active')
        }else if(scrollRects >= 2000){
            scrollFirst.value.classList.add('active')
            scrollTwo.value.classList.remove('active')
            scrollThree.value.classList.remove('active')
            scrollFour.value.classList.remove('active')
        }
    }
    onMounted(() => {
        window.addEventListener('scroll', handleScroll);
    });
    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
    });
</script>

<style lang="less" scoped>
    .claim-case-detail{
        border-radius: 20px;
        opacity: 1;
        background: linear-gradient(109deg, #488EFF 2%, #2072F5 105%);
        width: 111px;
        height: 40px;
        line-height:40px !important;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        margin-top:20px;
        cursor: pointer;
    }
    .claim-case-content{
        padding-top:42px;
    }
    .claim-case-item{
        position: relative;
        box-shadow:0px 0px 5px #eee;
        border-radius:12px;
        cursor:pointer;
    }
    .claim-case-title{
        border-radius: 10px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.8548) 0%, #FFFFFF 24%);
        height:185px;
        position: absolute;
        left:0;
        bottom:0;
        text-align: left;
        padding:0 21px;
        transition: all 0.25s linear;
        overflow: hidden;

    }
    .claim-case-item:hover .claim-case-title{
        height: 248px;
    }
    .claim-case-title h4{
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: bold;
        padding-top:20px;
    }
    .claim-case-text{
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.65);
        margin-top:20px;
    }
    .process-baoan{
        width:82px;
        height:82px;
        position: relative;
    }
    .process-baoan img{
        position: absolute;
        left: -20px;
        top: -17px;
        z-index:2
    }
    .claim-container {
        padding-top:78px;
        background: #fff;
    }    
    .claim-banner{
        background: url("../static/images/claim-banner.jpg?v=20250223") no-repeat center center;
        height: 521px;
        width: 100%;
        background-size: cover !important;
    }
    .claim-process{
        background: url("../static/images/process-bg.jpg?v=20250223") no-repeat center center;
        height: 2702px;
        width: 100%;
        background-size: cover !important;
    }
    .claim-service{
        padding:100px 0;
    }
    .claim-service h3{
        text-align:center;
        font-size: 36px;
        color: #333333;
        font-weight: bold;
        line-height: 54px;
    } 
    .claim-service-icon{
        display: flex;
        align-items: center;
    }
    .claim-service-text{
        font-size: 20px;
        color: rgba(0, 0, 0, 0.45);
        padding-top:8px;
    }
    .claim-service-content{
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        justify-content:space-between;
    }
    .claim-service-item{
        background: #F8F9FF;
        border-radius: 6px;
        padding:30px 28px;
        width:588px;
        margin-top:24px;
        transition: box-shadow 0.5s ease;
    }
    .claim-service-item:hover{
        box-shadow: 0px 3px 45px 0px rgba(0, 49, 102, 0.12);
    }
    .claim-service-item h4{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        padding-left:12px;
    }
    .claim-service-text{
        font-size: 16px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 32px;
        padding-top:16px;
    }
    .claim-process-left{
        text-align:left;

    }
    .claim-process-left h2{
        font-size: 32px;
        font-weight: 600;
        margin-top:40px;
        padding-bottom:14px;
        background: url("../static/images/process-h2bg.png?v=20250223") no-repeat left 20px;
    }
    .claim-process-left h2 span{
        color:#2072F5;
    }
    .claim-process-text{
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 35px;
        width:395px;
    }
    .active .rise-up{
        -webkit-animation: rise-up 1s forwards;
        animation: rise-up 1s forwards
    }
    @keyframes rise-up {
        0% {
            opacity: 0;
            -webkit-transform: translateY(100px);
            transform: translateY(100px)
        }

        to {
            opacity: 1;
            -webkit-transform: translateY(0);
            transform: translateY(0)
        }
    }
</style> 