
export const crumbsData = [
    {
        title:'全国可覆盖',
        imgUrl: require('@/static/images/crumbs-pic.png'),
        text:'手机电脑多端在线承保'
    },
    {
        title:'行业覆盖广',
        imgUrl: require('@/static/images/crumbs-pic1.png'),
        text:'1-6类职业均可保'
    },
    {
        title:'客户为先',
        imgUrl: require('@/static/images/crumbs-pic2.png'),
        text:'专业顾问1对1在线服务'
    },
    {
        title:'理赔无忧',
        imgUrl: require('@/static/images/crumbs-pic3.png'),
        text:'赔款最快24小时到账'
    },
]
export const GuaranteeData = [
    {
        title:'家电行业',
        imgUrl: require('@/static/images/guarantee-pic.png'),
        text:'家电行业雇主险必选品牌，每10个家电网点，有7个是我们的用户',
        link:'https://jiadian.meibaokeji.cn/employer/static/index.html?auth_first=lg'
    },
    {
        title:'高空（泛行业）',
        imgUrl: require('@/static/images/guarantee-pic2.png'),
        text:'行业职业覆盖广，电工建筑工也保，支持按月投保',
        link:'https://gk.meibaokeji.cn/employer/static/index.html?auth_first=lg'
    },
    {
        title:'光伏行业',
        imgUrl: require('@/static/images/guarantee-pic3.png'),
        text:'可按月投保，价优保额高，可保高空，雇主险、团意险，均可选',
        link:'https://gf.meibaokeji.cn/employer/static/index.html?auth_first=lg'
    },
    {
        title:'其他保险产品',
        imgUrl: require('@/static/images/guarantee-pic1.png'),
        text:'选择多多，涵盖低中高风险职业',
        link:'https://qtbx.meibaokeji.cn/employer/static/index.html?auth_first=lg'
    }
]
export const MajorData = [
    {
        title:'大品牌更放心',
        imgUrl: require('@/static/images/major-pic.png'),
        text:'拥有全国保险经纪牌照，与业内知名保险公司深度合作，用户覆盖31省，325个城市，真正的工人保险专家！'
    },
    {
        title:'专为不同工种定制',
        imgUrl: require('@/static/images/major-pic1.png'),
        text:'产品选择多，覆盖上百种1-6类职业，高空高危作业也能买，专为家电、物流、泛高空等行业基层劳动者提供保障。'
    },
    {
        title:'投保便捷 理赔省心',
        imgUrl: require('@/static/images/major-pic2.png'),
        text:'电脑手机随时投保，1人起保，最快当天结案；中途免费替换员工，短期工可按月投保，具体以保单条约为准'
    },
    {
        title:'科技为保险赋能',
        imgUrl: require('@/static/images/major-pic3.png'),
        text:'从用户出发，深度融合保险与互联网，采用大数据技术、AI科技，为高效的理赔服务赋能，为客户提供高品质有温度的投保理赔服务。'
    },
]
//  用户心声
export const AspirationImg = [
    {
        id: 1,
        data:[
            {
                text:'前阵子，我们网点安装师傅在客户家作业时，因操作失误造成客户损失。报案后理赔员耐心指导我怎么提交材料，最后不到24小时就收到了赔款，不愧是大品牌，专业放心!',
                name:'白先生',
                imgUrl: require('@/static/images/people1.png'),
                work:'家电网点负责人 安徽'
            },
            {
                text:'很多保险公司都不保带电的高空作业，经同行介绍，购买了美保平台的高空保险，还是世界500强太平洋保险承保，不仅保费便宜，保障还很全面，解决了后顾之忧，我们干活都更放心了!',
                name:'王先生',
                imgUrl: require('@/static/images/people4.png'),
                work:'电力检修企业老板 无锡市'
            },
            {
                text:'有次一个员工从高处掉下来受了重伤，在线上平台报案后，他们第二天就安排大案查勘经理过来，告诉我们理赔流程和注意事项，怎么收集资料，让我们感到很踏实，谢谢美保平台。',
                name:'张先生',
                imgUrl: require('@/static/images/people5.png'),
                work:'广告牌安装企业老板'
            }
        ] 
    },
    
  
];

// 合作客户图片
export const PartnerImg = [
    {
      id: 1,
      imgUrl: require('@/static/images/partner-pic.png'),
    },
    {
      id: 2,
      imgUrl: require('@/static/images/partner-pic1.png'),
    },
    {
      id: 3,
      imgUrl: require('@/static/images/partner-pic2.png'),
    },
    {
      id: 4,
      imgUrl: require('@/static/images/partner-pic3.png'),
    },
    {
      id: 5,
      imgUrl: require('@/static/images/partner-pic4.png'),
    },
    {
      id: 6,
      imgUrl: require('@/static/images/partner-pic5.png'),
    },
    {
        id: 7,
        imgUrl: require('@/static/images/partner-pic.png'),
      },
      {
        id: 8,
        imgUrl: require('@/static/images/partner-pic1.png'),
      },
      {
        id: 9,
        imgUrl: require('@/static/images/partner-pic2.png'),
      },
      {
        id: 10,
        imgUrl: require('@/static/images/partner-pic3.png'),
      },
      {
        id:11,
        imgUrl: require('@/static/images/partner-pic4.png'),
      },
      {
        id: 12,
        imgUrl: require('@/static/images/partner-pic5.png'),
      },
];
export const ServiceData = [
    {
        imgUrl: require('@/static/images/channel.png'),
        title:'多渠道便捷报案',
        text:'支持线上报案与电话报案，理赔热线7*24小时在线，随时随地响应客户需求，确保报案渠道畅通无阻'   
    },
    {
        imgUrl: require('@/static/images/service.png'),
        title:'一站式理赔服务',
        text:'提供从报案、勘查、定损，审核到赔付的一站式理赔服务，便捷高效，持续优化服务质量，确保客户服务满意度'   
    },
    {
        imgUrl: require('@/static/images/assist.png'),
        title:'全程协助',
        text:'从报案到结案，享受1对1专家全程指导，简化理赔流程，让理赔变得更加省心便捷'   
    },
    {
        imgUrl: require('@/static/images/prequalification.png'),
        title:'高效预审',
        text:'线上提交资料后，先进行电子预审，无误后再邮寄纸质文件至保险公司，加速理赔处理，提高效率'   
    },
    {
        imgUrl: require('@/static/images/transparent.png'),
        title:'透明无误',
        text:'实时反馈理赔进度，透明化管理，精准核算理赔金额，避免错赔，确保客户无异议后结案'   
    },
    {
        imgUrl: require('@/static/images/advance.png'),
        title:'超时垫付',
        text:'针对小额理赔案件，若超时未获赔付，经确认无误后，公司提供先行垫付服务，减轻客户经济压力'   
    },
]
export const caseData = [
    {
        title:'从1米滑落到3000元理赔，小保障也有大温度',
        imgUrl: require('@/static/images/case-pic.png'),
        text:'【小保障彰显大温度】保险生效仅四天，福州服务公司张师傅不慎从高处滑落，公司通过线上小程序进行报案。理赔专员全程协助，最终赔付…'
    },
    {
        title:'别家拒赔的"高空坠落"，为何我们敢赔近10万？',
        imgUrl: require('@/static/images/case-pic1.png'),
        text:'【高空惊魂，保险护航显神威！】江阴市服务公司陈师傅不幸从3米高空意外坠落，导致三根肋骨与左手臂骨折，最终保险赔付近10万…'
    },
    {
        title:'开工3天就出事？这根钢筋如何试出保险真谛？',
        imgUrl: require('@/static/images/case-pic2.png'),
        text:'【开工仅3日，钢筋砸出保险真金！】漳州建筑安装工程公司新工初启，不料开工三日，工人曲师傅不幸被钢筋击中右脚，理赔团队迅速…'
    },
]
export const navTwoData = [
    {
        title:'家电行业雇主险',
        imgUrl: require('@/static/images/jd-icon.png'),
        text:[
            '家电行业雇主险必选品牌',
            '每10个家电网点，有7个是我们的客户',
        ],
        footerTitle:'家电行业',
        link:'https://jiadian.meibaokeji.cn/employer/static/index.html?auth_first=lg'
    },
    {
        title:'高空雇主险（泛行业）',
        imgUrl: require('@/static/images/fgk-icon.png'),
        text:[
            '行业职业覆盖广，电工建筑工也能保',
            '支持按月投保，短期用工不再烦',
        ],
        footerTitle:'泛高空行业',
        link:'https://gk.meibaokeji.cn/employer/static/index.html?auth_first=lg'
    },
    {
        title:'光伏行业保险产品',
        imgUrl: require('@/static/images/fg-icon.png'),
        text:[
            '支持按月投保，价优保额高，可保高空',
            '雇主险，团意险，均可选择',
        ],
        footerTitle:'光伏行业',
        link:'https://gf.meibaokeji.cn/employer/static/index.html?auth_first=lg'
    },
    {
        title:'其他保险产品',
        imgUrl: require('@/static/images/qtbx-icon.png'),
        text:[
            '选择多多，涵盖低中高风险职业',
        ],
        footerTitle:'其他行业',
        link:'https://qtbx.meibaokeji.cn/employer/static/index.html?auth_first=lg'
    },
]
export const cooperators = [
    {
        name:'中国人民财产保险股份有限公司',
        link:'http://property.picc.com/',
        linkText:'property.picc.com',
        phone:'95518',
        startTime:'2024年9月30日',
        endTime:'2026年12月31日',
    },
    {
        name:'中国太平洋财产保险股份有限公司',
        link:'https://www.cpic.com.cn/',
        linkText:'cpic.com.cn',
        phone:'95500',
        startTime:'2024年12月1日',
        endTime:'2026年12月31日',
    },
    {
        name:'中国平安财产保险股份有限公司',
        link:'https://property.pingan.com/',
        linkText:'property.pingan.com',
        phone:'95512',
        startTime:'2024年9月16日',
        endTime:'2027年1月19日',
    },
    {
        name:'利宝保险有限公司',
        link:'https://www.libertymutual.com.cn/',
        linkText:'libertymutual.com.cn',
        phone:'400 888 2008',
        startTime:'2024年11月1日',
        endTime:'2026年10月31日',
    },
    {
        name:'美亚财产保险有限公司',
        link:'https://www.aig.com.cn/',
        linkText:'aig.com.cn',
        phone:'400 820 8858',
        startTime:'2024年10月25日',
        endTime:'2025年10月24日',
    },
    {
        name:'紫金财产保险股份有限公司',
        link:'https://www.zking.com/',
        linkText:'zking.com',
        phone:'95312',
        startTime:'2025年3月1日',
        endTime:'2027年12月9日',
    },
    {
        name:'泰康在线财产保险股份有限公司',
        link:'https://www.tk.cn/',
        linkText:'tk.cn',
        phone:'95522',
        startTime:'2024年12月10日',
        endTime:'2026年12月9日',
    },
    {
        name:'中国人寿财产保险股份有限公司',
        link:'https://www.chinalife-p.com.cn/#/',
        linkText:'chinalife-p.com.cn',
        phone:'95519',
        startTime:'2021年1月1日',
        endTime:'2030年12月31日',
    },
]

