<template>
    <div class="information-container">
        <div class="infor-main">
			<div class="disclosure-crumbs">
				<a @click="routerBtn('/')">首页</a> / <a @click="routerBtn('/disclose')">信息披露</a> /  代理保险产品信息披露
			</div>
			<div class="disclosure-table">
				<h2>代理保险产品信息披露</h2>
				<table>
					<tr>
							<th>保险产品名称</th>
							<th>条款名称</th>
							<th>备案编号</th>
						</tr>
						<tr>
							<td>太保1-5类团意险	</td>
							<td>
								《团体人身意外伤害保险(互联网)条款》<br/>
								附加团体意外伤害医疗保险(互联网)条款》
							</td>
							<td>
								C00001432312021122327333<br/>C00001432522021122028753
							</td>
						</tr>
						<tr>
							<td>太保1-6类团意险</td>
							<td>
								《团体人身意外伤害保险(互联网)条款》<br/>
								《附加团体意外伤害医疗保险(互联网)条款》<br/>
								《附加团体意外伤害住院津贴保险(互联网)条款》
							</td>
							<td>
								C00001432312021122327333<br/>C00001432522021122028753<br/>C00001432522021122028633
							</td>
						</tr>

						<tr>
							<td>国寿财4至6类团意高空版</td>
							<td>
								中国人寿财产保险股份有限公司人身意外伤害保险(2022版)(互联网专属)条款<br/>
								中国人寿财产保险股份有限公司意外伤害保险(互联网专属)类附加年龄规范保险条款
							</td>
							<td>
								C00010832312022042867271<br/>C00010832322021122437413
							</td>
						</tr>
						
						<tr>
							<td>“关爱保”灵活用工意外险（吉泰）</td>
							<td>
								中国人民财产保险股份有限公司意外伤害保险B款（互联网专属）条款<br/>
								中国人民财产保险股份有限公司附加意外伤害医疗保险A款（互联网专属）条款
							</td>
							<td>
								C00000232312021122732193<br/>C00000232522021122029123
							</td>
						</tr>
						<tr>
							<td>人保5至6类个人意外险（高空版）（吉泰）</td>
							<td>
								中国人民财产保险股份有限公司意外伤害保险B款（互联网专属）条款<br/>
								中国人民财产保险股份有限公司附加意外伤害医疗保险A款（互联网专属）条款<br/>
								中国人民财产保险股份有限公司附加意外伤害住院津贴保险A款（互联网专属）条款
							</td>
							<td>C00000232312022012700681<br/>C00000232522021122029123<br/>C00000232522021122434983</td>
						</tr>
						<tr>
							<td>利宝保险雇主责任保险（1-6类MB）普惠版</td>
							<td>利宝保险有限公司雇主责任保险条款（2024版）<br/>
								利宝保险有限公司雇主责任保险附加伤残赔偿比例调整保险条款（A款）<br/>
								利宝保险有限公司雇主责任保险附加交通事故死亡伤残赔偿标准保险条款（B款）<br/>
								利宝保险有限公司附加传染病责任免除条款<br/>
								利宝保险有限公司雇主责任保险附加雇员承保年龄保险条款<br/>
								利宝保险有限公司雇主责任保险附加雇员高风险职业除外保险条款<br/>
								利宝保险有限公司雇主责任保险附加安全管理保险条款（B款）<br/>
								利宝保险有限公司雇主责任保险附加雇员列明承保职业等级保险条款<br/>
								利宝保险有限公司雇主责任保险附加批改方式保险条款（A款）<br/>
								利宝保险有限公司雇主责任保险附加特种作业保险条款（B款）</td>
							<td>
								C00006030912024061207293<br/>
								C00006030922023020522683<br/>
								C00006030922024022839441<br/>
								C00006031922021012002402<br/>
								C00006030922023040461183<br/>
								C00006030922023040461193<br/>
								C00006030922024091005703<br/>
								C00006030922023040461213<br/>
								C00006030922024010502351
							</td>
						</tr>
						<tr>
							<td>利宝保险雇主责任保险（1-6类MB）</td>
							<td>
								利宝保险有限公司雇主责任保险条款（2024版）<br/>
								利宝保险有限公司雇主责任保险附加突发疾病死亡保险条款（A款）<br/>
								利宝保险有限公司雇主责任保险附加伤残赔偿比例调整保险条款（A款）<br/>
								利宝保险有限公司雇主责任保险附加交通事故死亡伤残赔偿标准保险条款（B款）<br/>
								利宝保险有限公司附加传染病责任免除条款<br/>
								利宝保险有限公司雇主责任保险附加雇员承保年龄保险条款<br/>
								利宝保险有限公司雇主责任保险附加雇员高风险职业除外保险条款<br/>
								利宝保险有限公司雇主责任保险附加安全管理保险条款（A款）<br/>
								利宝保险有限公司雇主责任保险附加高职低投保险条款（A款）<br/>
								利宝保险有限公司雇主责任保险附加批改方式保险条款（A款）<br/>
								利宝保险有限公司雇主责任保险附加特种作业保险条款（A款）</td>
							<td>
								C00006030912024061207293<br/>
								C00006030922023100862251<br/>
								C00006030922023020522683<br/>
								C00006030922024022839441<br/>
								C00006031922021012002402<br/>
								C00006030922023040461183<br/>
								C00006030922023040461193<br/>
								C00006030922024010502101<br/>
								C00006030922024010502161<br/>
								C00006030922024010502351<br/>
								C00006030922024010502331
							</td>
						</tr>
						<tr>
							<td>美亚和顺保互联网团体意外伤害保险C款</td>
							<td>
								美亚附加经济制裁责任免除条款；<br/>
								美亚附加仅承保境内意外事故条款（2021年第二版）（互联网专属）<br/>
								美亚附加每一意外事故总赔偿限额条款（2021年第一版）（互联网专属）<br/>
								美亚附加特种作业限制条款（2021年第二版）（互联网专属）<br/>
								美亚附加特定职业类别人员高处作业条款（2024年第二版）<br/>
								美亚附加被保险人资格条款（2024年第二版）<br/>
								美亚附加被保险人职业类别处理规则条款（2022年第一版）<br/>
								美亚附加短期保险费条款（2022年第一版）<br/>
								美亚团体意外伤害保险（2024年第一版）（互联网专属）<br/>
								美亚附加意外医药补偿团体医疗保险（2024年第一版）（互联网专属）<br/>
								美亚附加意外每日住院定额给付团体医疗保险（2024年第二版）（互联网专属）<br/>
								美亚附加监护病房定额给付团体医疗保险（2024年第一版）（互联网专属）<br/>
								美亚附加猝死团体保险（2024年第一版）（互联网专属）<br/>
								美亚附加交通工具团体意外伤害保险（2022年第一版）（互联网专属）
							</td>
							<td>
								C00003931922019052907382(美亚财险)(备-其他)【2019】(附) 017号<br/>
								C00003932322021122437063(美亚财险)(备-普通意外保险)【2021】(附) 183号<br/>
								C00003932322021122437463(美亚财险)(备-普通意外保险)【2021】(附) 203号<br/>
								C00003932322021122437203(美亚财险)(备-普通意外保险)【2021】(附) 197号<br/>
								C00003931922024052005473(美亚财险)(备-其他)【2024】(附) 077号<br/>
								C00003931922024022839291美亚产品[2024]第19号<br/>
								C00003931922022112966391(美亚财险)(备-其他)【2024】(附) 010号<br/>
								C00003931922022112966371(美亚财险)(备-其他)【2024】(附) 046号<br/>
								C00003932312024052005413美亚产品[2024]第33号<br/>
								C00003932522024052005423(美亚财险)(备-医疗保险)【2024】(附) 080号<br/>
								C00003932522024052005433(美亚财险)(备-医疗保险)【2024】(附) 071号<br/>
								C00003932522024052005443(美亚财险)(备-医疗保险)【2024】(附) 076号<br/>
								C00003931922024052005453(美亚财险)(备-其他)【2024】(附) 081号<br/>
								C00003932322022012183253(美亚财险)(备-普通意外保险)【2021】(附) 212号
							</td>
						</tr>
						<tr>
							<td>平安意外伤害保险(互联网)-A款</td>
							<td>平安产险附加意外伤害住院津贴保险（互联网版）<br/>平安产险意外伤害保险（互联网版）</td>
							<td>C00001732522022072500061<br/>C00001732312024062802603</td>
						</tr>
						<tr>
							<td>平安意外伤害保险(互联网)-B款</td>
							<td>平安产险附加意外伤害住院津贴保险（互联网版）<br/>平安产险意外伤害保险（互联网版）</td>
							<td>C00001732522022072500061<br/>C00001732312024062802603</td>
						</tr>
						<tr>
							<td>太保家电雇主险美的版-高空（社保补充）</td>
							<td>
								雇主责任保险（2016版）<br/>
								中国太平洋财产保险股份有限公司雇主责任保险附加伤残赔偿金赔偿限额比例条款<br/>
								第三者责任条款；24小时意外
							</td>
							<td>
								C00001430912016110806772(太保财险)(备-责任保险)【2016】(主) 063号<br/>
								C00001430922021060478582(太保财险)(备-责任保险)【2021】(附) 166号<br/>
								H00001430922017052483531太保（备案）[2009]N381号<br/>
								H00001430922017052432831（太保财险）（备-责任）[2013]（附）192号
							</td>
						</tr>
						<tr>
							<td>泛行业高空雇主险</td>
							<td>雇主责任保险</td>
							<td>H00001430912017052483431太保（备案）[2009]N380号</td>
						</tr>
						<tr>
							<td>泛高空雇主险-光伏行业专属方案尊贵版</td>
							<td>雇主责任保险</td>
							<td>H00001430912017052483431太保（备案）[2009]N380号</td>
						</tr>
						<tr>
							<td>泛高空雇主险-光伏行业专属方案普通版</td>
							<td>雇主责任保险</td>
							<td>H00001430912017052483431太保（备案）[2009]N380号</td>
						</tr>	
						<tr>
							<td>太保电梯安装维保人员雇主险</td>
							<td>雇主责任保险</td>
							<td>H00001430912017052483431太保（备案）[2009]N380号</td>
						</tr>
						<tr>
							<td>美保平台泛高空雇主责任险（高责高价）	</td>
							<td>雇主责任保险</td>
							<td>H00001430912017052483431太保（备案）[2009]N380号</td>
						</tr>
					</table>
				</div>
			</div>

    </div>
</template>

<script setup>
    import { useRouter } from 'vue-router';
	const router = useRouter();
	const routerBtn = (url) => {
        window.scrollTo(0, 0);
        router.push(url);
    }
</script>

<style lang="less" scoped>
    .information-container{
        padding-top:78px;
        padding-bottom:100px;
    }
    .infor-main{
        width:1200px;
        margin:auto;
    }
    .disclosure-crumbs{
        text-align: left;
        line-height: 60px;
        color: #999999;
        font-size: 16px;
    }
    .disclosure-crumbs a{
        font-size: 16px;
        color: #999999;
    }
    .disclosure-crumbs a:hover{
        color:#0068B7;
    }
    .disclosure-table{
	padding:30px;
	background: #fff;
}
.disclosure-table h2{
	color: #333333;
	font-weight: bold;
	text-align: center;
	font-size: 24px;
	padding-bottom:30px;
}
.disclosure-table table{
	border:1px solid #EEEEEE;
	width:100%;
	border-left:none;
    text-align: left;
}
.disclosure-table table th{
	background: #0068B7;
	line-height: 50px;
	font-size:16px;
	color: #FFFFFF;
	text-align: left;
	padding-left:20px;
	border-left:1px solid #EEEEEE;
}
.disclosure-table table td{
	border-left:1px solid #EEEEEE;
	padding:18px 20px;
	color: #333333;
	font-size:16px;
	border-top:1px solid #EEEEEE;
}
.disclosure-table table td a{
	color: #1890FF;
	font-size:16px;
}
</style> 