import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import 'ant-design-vue/dist/reset.css';
import router from './router';
import '@/static/less/common.less';
import '@/static/less/font.css'
import request from './utils/request'
const app = createApp(App);
app.use(Antd).use(router);
app.mount('#app')
app.config.globalProperties.$post = request.post
app.config.globalProperties.$postJson = request.postJson
app.config.globalProperties.$get = request.get
app.config.globalProperties.$put = request.put
app.config.globalProperties.$putJson = request.putJson
app.config.globalProperties.$delete = request.delete
app.config.globalProperties.$export = request.export