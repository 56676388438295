<template>
    <div class="information-container">
        <!-- Banner区域 -->
        <img src="@/static/images/information-bg.png" alt="" style="width:100%;display: block;margin-top:78px">
         <div style="background:#F5F7FD;line-height:56px;"> 
            <div class="infor-advertisement index-aspiration-content" @click="advertisementBtn">
                <div class="infor-advertisement-title">
                    广东美保保险经纪有限公司关于换领保险中介许可证的公告：
                </div>
                <div id="box"  ref="box" @mouseover="stopScroll" @mouseout="startScroll">
                <div id="warp">
                        <div id="conBegin" ref="conBegin">
                            经国家金融监督管理总局广东监管局批准，我司变更名称及地址获准换领保险中介许可证。根据《银行保险机构许可证管理办法》（中国银行保险监督管理委员会令2021年第3号）等相关规定，现予以公告。
                        </div>
                        <div id="conEnd">
                        经国家金融监督管理总局广东监管局批准，我司变更名称及地址获准换领保险中介许可证。根据《银行保险机构许可证管理办法》（中国银行保险监督管理委员会令2021年第3号）等相关规定，现予以公告。
                        </div>
                    </div>
                </div>
            </div>
        </div>     
        <div class="infor-main index-aspiration-content" style="padding-top:0;">
			<div class="infor-company">
				<h2>公司概况</h2>
				<p><strong>公司名称：</strong><span>广东美保保险经纪有限公司</span></p>
				<p><strong>注册资本：</strong><span>5000万元</span></p>
				<p><strong>成立时间：</strong><span>2012年2月24日</span></p>
				<p><strong>法定代表人：</strong><span>孔杨君</span></p>
				<p><strong>公司住所：</strong><span>广东省佛山市顺德区北滘镇君兰社区诚德路1号美的财富广场4栋27层2706</span></p>
				<p><strong>业务范围和经营区域：</strong><span>在全国区域内(港、澳、台除外)为投保人拟订投保方案、选择保险人、办理投保手续；协助被保险人或受益人进行索赔；再保险经纪业务；为委托人提供防灾、防损或风险评估、风险管理咨询服务；中国保监会批准的其他业务。</span></p>
			</div>
			<div class="infor-company">
				<h2>经营执照</h2>
				<ul class="infor-license">
					<li>
                        <a-image :width="360" :src="informationImgHover" :height="254"/>
						<div>保险经纪牌照</div>
					</li>
					<li>
                        <a-image :width="360" :src="twoImgHover" :height="254"/>
						<div>工商营业执照</div>
					</li>
						<li>
                            <a-image :width="360" :src="threeImgHover" :height="254"/>
							<div>信息系统安全等级保护备案证明</div>
						</li>
					</ul>
				</div>
				<div class="infor-company" style="display:none">
					<h2>自营平台</h2>
					<div class="infor-platform">
						<div><strong>经营互联网保险业务的微信小程序名称：</strong></div>
						<ul class="infor-platform-list">
							<li>
								<img src="@/static/images/information-newpic.png" alt="" style="width:6.25vw;height:6.25vw;">
								<div>保险经纪牌照</div>
							</li>
							<li>
								<img src="@/static/images/information-newpic.png" alt="" style="width:6.25vw;height:6.25vw;">
								<div>保险经纪牌照</div>
							</li>
						</ul>	
					</div>
				</div>
				<div class="infor-company">
					<h2>合作保险公司信息</h2>
					<div class="infor-href">
						<a @click="routerBtn('/cdisclosure')">合作保险公司披露</a>
					</div>
					
				</div>
				<div class="infor-company">
					<h2>代理产品信息</h2>
					<div class="infor-href">
						<a @click="routerBtn('/agent')">代理保险产品信息披露</a>
					</div>
				</div>
			</div>
    </div>
    <a-modal v-model:open="open" width="1000px"  :footer='null'>
        <div class="infor-modal">
            <div class="infor-modal-title">
                广东美保保险经纪有限公司<br>关于换领保险中介许可证的公告
            </div>
            <p>经国家金融监督管理总局广东监管局批准，我司变更名称及地址获准换领保险中介许可证。根据《银行保险机构许可证管理办法》（中国银行保险监督管理委员会令2021年第3号）等相关规定，现予以公告。 </p>
            <p>一、机构名称：广东美保保险经纪有限公司 </p>
            <p>二、机构住所：广东省佛山市顺德区北滘镇君兰社区诚德路1号 美的财富广场4栋27层2706(住所申报) </p>
            <p>三、机构编码：269602000000800 </p><p>四、联系电话：020-22629362 </p>
            <p>五、批准日期：2012年01月20日 </p><p>六、发证日期：2024年12月25日 </p>
            <p>七、业务范围：在全国区域内(港、澳、台除外)为投保人拟订投 保方案、选择保险人、办理投保手续;协助被保险人或受益人进行索赔;再保险经纪业务;为委托人提供防灾、防损或风险评估、风险管理咨询服务;保险监督管理机构批准的其他业务。 </p><p>特此公告。</p><p style="text-align:right">广东美保保险经纪有限公司</p>
            <p style="text-align:right"> 2025年1月2日</p>
        </div>
    </a-modal>
</template>

<script setup>
    import { ref, onMounted, onUnmounted } from 'vue';
    import { useRouter } from 'vue-router';
    import informationImgHover from '@/static/images/information-newpic.png';
    import twoImgHover from '@/static/images/information-newpic2.png';
    import threeImgHover from '@/static/images/information-newpic3.png';
    const router = useRouter();
    const routerBtn = (url) => {
        window.scrollTo(0, 0);
        router.push(url);
       
    }
    const open = ref(false);
    const box = ref(null); // 获取 box 元素
    const conBegin = ref(null); // 获取 conBegin 元素
    let timer = null; // 定时器
    const advertisementBtn = () => {
        open.value = true;
    }
    // 滚动函数
    const move = () => {
        if (!box.value || !conBegin.value) return;
        const curLeft = box.value.scrollLeft; // 当前滚动位置
        const conBeginWidth = parseInt(window.getComputedStyle(conBegin.value).width); // conBegin 的宽度
        box.value.scrollLeft = curLeft + 1; // 向右滚动
        // 如果滚动到末尾，回到起点
        if (curLeft > conBeginWidth) {
                box.value.scrollLeft = 0;
        }
    };
    // 启动滚动
    const startScroll = () => {
        timer = setInterval(move, 15);
    };
    // 停止滚动
    const stopScroll = () => {
        clearInterval(timer);
    };
    // 组件挂载时启动滚动
    onMounted(() => {
        startScroll();
    });
    // 组件卸载时清除定时器
    onUnmounted(() => {
        clearInterval(timer);
    });
</script>

<style lang="less" scoped>
    .infor-modal-title{
        text-align: center;
        color: #333333;
        font-size: 1.56vw;
        padding-bottom:20px;
    }
    .infor-modal p{
        color: #333333;
        line-height:36px;
        font-size: 0.83vw;
    }
     #box {
		white-space: nowrap;
		overflow: hidden;
	}
	#warp div {
		display: inline-block;
	}
    .information-container{
        background:#fff;
    }
    .infor-main{
        text-align: left;
        padding-bottom:100px;
    }
    .infor-company h2{
        font-weight: bold;
        font-size: 22px;
        color: #333333;
        padding-top:60px;
    }
    .infor-company p{
        padding-top:20px;
        color:#666;
        font-size:16px;
        line-height:26px;
        overflow: hidden;
    }
    .infor-company p strong{
        color:#333;
        display: block;
        float: left;
        max-width: 25vw;
    }
    .infor-company p span{
        display: block;
    }
    .infor-license{
        display: flex;
        padding-top: 30px;
        justify-content: space-between;

    }
    .infor-license li div{
        font-size: 14px;
        color: #999999;
        line-height: 20px;
        padding-top:10px;
        text-align: center;
    }
    .infor-href{
        padding-top:20px;
    }
    .infor-href a{
        font-size: 16px;
        color: #1890FF;
    }
    .infor-advertisement{
        padding:10px 0;
        color:#0068B7;
        display: flex;
        cursor: pointer;
        font-size: 16px;
    }
    .infor-advertisement-title{
        background: url(../static/images/advertisement.png)no-repeat left 18px;
        padding-left:30px;
        min-width: 480px;
        font-size: 16px;

    }
    .infor-advertisement div{
        font-size:16px;
    }
</style> 