<template>
    <div class="information-container">
        <div class="infor-main">
			<div class="disclosure-crumbs">
				<a @click="routerBtn('/')">首页</a> / <a @click="routerBtn('/claim')">理赔服务</a> / 案例详情
			</div>
			<div class="window-width" style="display: flex;justify-content:space-between">
				<div class="list-left">
					<template v-if="type == 0">
						<div class="list-left-title">从1米滑落到3000元理赔，小保障也有大温度</div>
						<h3>理赔概况</h3>
						<p>1、企业名称：福州**服务有限公司</p>
						<p>2、投保方案：太平洋雇主险</p>
						<p>3、出险经过：清洗风机时，不慎脚踩空从一米多高的风机上滑下来，导致屁股划伤</p>
						<p>4、理赔金额：医疗费1640.85元，误工费1500元，<strong style="color:red">合计3140.85元</strong></p>
						<h3>理赔经过</h3>
						<p>在福州这座充满活力的城市里，在各行各业如火如荼地忙碌中，迎来了2024年的新年钟声。</p>
						<p>在这样的背景下，**服务有限公司的曾总展现出了他作为企业家的远见卓识。为了确保项目能够按时完工，同时也为了给奋斗在一线的工人们多一份安心，他于1月9日做出了一个明智的决定——为公司的施工团队买份保险。经过慎重的挑选与对比，最终选择了与美保合作，投保了太平洋雇主险；<strong>当天投保，次日保险便生效</strong>，这份及时的保障就像冬日里的一缕暖阳，不仅温暖了工人们的心，也为他们的工作增添了一份安全感。</p>
						<img src="@/static/images/pic1.png" alt="" style="width:100%;padding-top:20px;"/>
						<p>然而，世事难料，就在保险生效后的第四天，一场小意外悄然降临。张师傅是一位经验丰富的施工人员，当天他正专心致志地清洗着高大的风机，不料在一次转身调整位置时，脚下一滑，整个人失去平衡，从一米多高的风机边缘滑落下来，那一刻，时间仿佛凝固，张师傅的心中充满了不安。幸运的是，仅仅划伤了屁股，但疼痛让他一时难以起身。公司迅速反应，第一时间将张师傅送往医院接受治疗，经全面检查，张师傅只伤到了皮肉，骨头没事，也算不幸中的万幸。</p>
						<img src="@/static/images/pic2.png" alt="" style="width:100%;padding-top:20px;"/>
						<p>看着张师傅痛苦的表情，曾总心里五味杂陈，既有对员工的担忧，也有对工程进度的焦虑。但更让他挂心的是，这种意外是否能得到妥善的处理和赔偿。于是，他立即通过博维线上快赔小程序进行线上报案。</p>
						<p>美保的理赔团队没有让曾总失望。接到报案后，他们迅速行动，指派理赔专员一对一跟进，细致询问事故的具体情况，同时耐心地指导公司如何收集并保存好所有相关资料。在这个过程中，美保的专业与高效让曾总感受到了前所未有的安心。理赔专员不仅及时解答了所有疑问，还主动跟进理赔进度，耐心指导如何上传理赔材料等，确保理赔的每一个环节都顺畅无阻。</p>
						<img src="@/static/images/pic3.png" alt="" style="width:100%;padding-top:20px;"/>
						<p>经过57天的耐心等待，张师傅终于治疗结束，在理赔员等耐心指导下，公司递交了全部的索赔材料，经审核材料无误后，<strong style="color:red">最终赔付了医疗费1640.85元以及误工费1500元，合计3140.85元。</strong>至此理赔案件也有了圆满的结果，当这笔理赔款到账的那一刻，曾总感激地说到：“这次意外让我深刻体会到了保险的重要性，美保的快速理赔让我感受到了真正的关怀与温暖。”</p>
						<img src="@/static/images/pic4.png" alt="" style="width:100%;padding-top:20px;"/>
						<p>这次经历，不仅加深了**服务有限公司与美保之间的信任与合作，也让曾总成为了美保的忠实拥趸。他开始主动向身边的朋友、同行推荐美保的保险服务，希望更多像他一样关心员工、注重安全的企业能够得到这份坚实的后盾。</p>
						<h3>理赔启示</h3>
						<p>在这个故事中，我们看到了保险的力量——它不仅仅是一份冰冷的合同，更是企业与员工之间情感的纽带，是风雨同舟、共渡难关的承诺。美保用实际行动诠释了<strong>“为基层劳动者提供保障”</strong>的承诺，让每一个努力生活的人都拥有了更多
							的保障和希望。</p>
					</template>
					<template v-else-if="type == 1">
						<div class="list-left-title">别家拒赔的"高空坠落"，为何我们敢赔近10万？</div>
						<h3>理赔概述</h3>
						<p>1、企业名称:江阴市**服务有限公司</p>
						<p>2、投保方案:太平洋雇主险</p>
						<p>3、出险经过:师傅清洗外墙的时候从3米左右的脚手架上意外摔下来，导致3根肋骨骨折、左手臂骨折</p>
						<p>4、理赔金额：医疗费15676.21元，误工费9000元，伤残金70000元，<strong style="color:red">合计赔付94676.21元</strong></p>
						<h3>理赔经过</h3>
						<p>步入2023年，江阴市**服务有限公司在夏总的精心挑选下，于2月10日迅速携手美保，为全年高空作业安全投保太平洋雇主险。这一前瞻性的决策，彰显了夏总对员工生命安全的深切关怀。</p>
						<img src="@/static/images/pic5.png" alt="" style="width:100%;padding-top:20px;"/>
						<p>随着工程推进，面对人员更替，公司于6月未雨绸缪，及时在美保协助下更新保险名单，确保每位新上岗工人都能获得即时有效的保障。然而，保险生效后的不久，陈师傅在清洗外墙的时候从3米左右的脚手架上意外摔下来，导致3根肋骨骨折、左手臂骨折，还好佩戴安全带，不然后果不堪设想。</p>
						<img src="@/static/images/pic6.png" alt="" style="width:100%;padding-top:20px;"/>
						<p>事故突发，公司迅速行动，第一时间将陈师傅送往医院救治，并立即通过线上小程序进行报案。接到报案后，美保理赔专员即刻响应，派遣专业理赔顾问一对一跟进，细致询问事故细节，同时细致指导公司收集并妥善保管理赔所需材料。</p>
						<img src="@/static/images/pic7.png" alt="" style="padding-top:20px;"/>
						<p>历经266天的精心治疗与伤残评定，陈师傅逐步康复。美保理赔专员在此期间保持密切沟通，确保理赔流程无碍。最终，保险公司审核通过全部材料，迅速赔付医疗费、误工费及伤残赔偿金，<strong style="color:red">总计94,676.21元，</strong>为企业提供了坚实的经济支撑。</p>
						<img src="@/static/images/pic8.png" alt="" style="padding-top:20px;"/>
						<p>此次理赔经历不仅彰显了太平洋雇主险的保障实力与美保服务的专业高效，也让江阴市**服务有限公司深刻体会到，通过<strong>科学配置保险资源，能够有效提升企业应对突发事件的能力，</strong>保障员工权益，促进企业稳健前行。夏总对此表示高度认可，并计划未来进一步深化与保险公司的合作，为全体员工构建更加牢固的安全屏障。</p>
					</template>
					<template v-else-if="type == 2">
						<div class="list-left-title">开工3天就出事？这根钢筋如何试出保险真谛？</div>
						<h3>理赔概况</h3>
						<p>1、企业名称:漳州**建筑安装工程有限公司</p>
						<p>2、投保方案:太平洋雇主险</p>
						<p>3、出险经过：10月2号下午曲师傅在工地不小心被钢筋砸到右脚，导致右脚肌腱断裂，10月2号晚上送至医院治疗</p>	
						<p>4、理赔金额：医疗费4788.98元，误工费9000元，<strong style="color:red">合计赔付13788元</strong></p>
						<h3>理赔经过</h3>
						<p>2023年的金秋时节，漳州**建筑安装工程有限公司的工地上一片繁忙，工人们正热火朝天地投入到新的建设项目中。9月26日，随着工程的正式启动，公司的刘总为了确保员工的安全，未雨绸缪，决定立即为全体员工投保太平洋雇主责任险。他找到了美保的客户经理，迅速完成了投保手续，<strong style="color:red">当天投保，次日正式生效。</strong></p>	
						<img src="@/static/images/pic9.png" alt="" style="width:100%;padding-top:20px;"/>
						<img src="@/static/images/pic10.png" alt="" style="padding-top:20px;"/>
						<p>然而，命运似乎总爱与人开玩笑。在开工三天后的10月2日下午，一场突如其来的意外打破了工地的平静。曲师傅在搬运物品时不慎被一根掉落的钢筋砸到了右脚。伴随着一声痛苦的呼喊，曲师傅也瘫倒在地上。工地上的同事见状，立刻拨打了120救护车，将曲师傅送往最近的医院进行救治，经诊断右脚约2cm裂伤。</p>
						<img src="@/static/images/pic11.png" alt="" style="padding-top:20px;"/>
						<p>当日晚上，曲师傅在医院接受了紧急治疗，右脚得到了有效的修复。然而，这场意外不仅给曲师傅带来了身体上的痛苦，也给公司带来了不小的经济压力。</p>	
						<p>面对这一突发情况，漳州**建筑安装工程有限公司的负责人迅速反应，通过线上小程序进行了报案。美保的理赔专员在接到报案后，第一时间与公司负责人取得了联系，详细询问了事故经过，并指导公司如何收集、整理理赔所需的各项材料。在整个理赔过程中，美保的理赔专员始终保持着与公司负责人的密切沟通，耐心指导他们如何提交材料、就医时需要注意哪些事项等，确保了理赔流程的透明度和顺畅性，展现出了高效、专业的服务态度。</p>
						<img src="@/static/images/pic12.png" alt="" style="padding-top:20px;"/>
						<p>经过55天的治疗，曲师傅顺利出院，并提交了详细的医疗费用清单在内的全部相关材料，由于公司提供的材料齐全真实，且整个理赔过程符合规定，因此案件<strong>一次性审核通过</strong>。最终，公司获得了医疗费4788.98元和误工费9000元的赔付，<strong style="color:red">合计13788元</strong>。</p>	
						<img src="@/static/images/pic13.png" alt="" style="padding-top:20px;"/>
						<p>这次理赔经历让漳州**建筑安装工程有限公司深刻体会到了保险的重要性以及美保理赔服务的专业性。在未来的日子里，他们将继续关注员工的安全与健康，同时也将把美保作为值得信赖的合作伙伴，共同为企业的稳健发展保驾护航。</p>	
						<h3>理赔启示</h3>
						<p>漳州**建筑安装工程有限公司的理赔案例为我们提供了在保险领域的重要启示。企业应及时购买合适的保险，确保保险覆盖；在事故发生后及时报案，提交真实、详细的理赔资料；同时，选择专业、高效的理赔服务，以确保在面临风险时能够得到及时、有效的保障。</p>					
					</template>	
				</div>
				<div class="list-right">
					<div>
						<div class="list-right-title">其他案例</div>
						<div class="list-right-item" v-if="type != 0" @click="setTypeToZero(0)">
							<img src="@/static/images/case-hoverpic.png" width="272" alt="">
							<p>从1米滑落到3000元理赔，小保障也有大温度</p>
						</div>
						<div class="list-right-item"  v-if="type != 1" @click="setTypeToZero(1)">
							<img src="@/static/images/case-hoverpic1.png" width="272" alt="">
							<p>别家拒赔的"高空坠落"，为何我们敢赔近10万？</p>
						</div>
						<div class="list-right-item"  v-if="type != 2" @click="setTypeToZero(2)">
							<img src="@/static/images/case-hoverpic2.png" width="272" alt="">
							<p>开工3天就出事？这根钢筋如何试出保险真谛？</p>
						</div>
					</div>
					

				</div>
			</div>
		</div>

    </div>
</template>

<script setup>
	import { onMounted,ref} from 'vue';
    import {  useRouter,useRoute} from 'vue-router';
	const router = useRouter();
	const route = useRoute();
	const type = ref('')
	const routerBtn = (url) => {
        window.scrollTo(0, 0);
        router.push(url);
    }
	const listInit = () => {
		type.value = route.query.id
	}
	const setTypeToZero = (val) => {
		type.value = val
	}
	onMounted(() => {
        listInit()
    });
</script>

<style lang="less" scoped>
	.list-right{
		width:272px;
	}
	.list-right-item{
		padding-top:32px;
		cursor: pointer;
	}
	.list-right-item p{
		text-align: left;
		font-size: 16px;
		color: #333333;
		padding-top:12px;
	}
	.list-right-item:hover p{
		color:#2878F7
	}
	.list-right-title{
		font-size: 24px;
		color: #333333;
		text-align: left;
		padding-bottom: 15px;
		border-bottom: 1px solid #EEEEEE;
	}
    .information-container{
        padding-top:78px;
        padding-bottom:100px;
		background:#fff;
    }
    .infor-main{
        width:1200px;
        margin:auto;
    }
    .disclosure-crumbs{
        text-align: left;
        line-height: 60px;
        color: #999999;
        font-size: 16px;
    }
    .disclosure-crumbs a{
        font-size: 16px;
        color: #999999;
    }
    .disclosure-crumbs a:hover{
        color:#0068B7;
    }
    .list-left{
		width:860px;
		text-align:left;
	}
	.list-left-title{
		font-size: 36px;
		font-weight: bold;
		line-height: normal;
		color: #333333;
		padding-bottom: 20px;
	}
	.list-left h3{
		font-size: 24px;
		font-weight: bold;
		color: #333333;
		padding-top:20px;
	}
	.list-left p{
		font-size: 18px;	
		line-height: 28px;
		color: rgba(0, 0, 0, 0.85);
		padding-top:20px;
	}
</style> 